import { useProfileStore } from "@/store/profileStore"
import { useSubscriptionStore } from "@/store/subscriptionStore"
import { useChannelTalk } from "@/hooks/useChannelTalk"
import { logout } from "@/app/api/logout/api"

import WorkspaceSwitch from "./WorkspaceSwitch"
import MenuItem from "./MenuItem"
import Popover from "@mui/material/Popover"

// icons
import LogoutIcon from "@/assets/icons/logout.svg"
import SettingsIcon from "@/assets/icons/settings.svg"
import BookIcon from "@/assets/icons/book-closed.svg"
import HelpIcon from "@/assets/icons/help-circle.svg"
import Settings2Icon from "@/assets/icons/settings-02.svg"
import CardIcon from "@/assets/icons/credit-card-02.svg"
import SwitchIcon from "@/assets/icons/switch.svg"
import ArrowRightIcon from "@/assets/icons/arrow-right-01.svg"
import TeamIcon from "@/assets/icons/users-03.svg"
import InfinityIcon from "@/assets/icons/infinity-icon.svg"
import AlertCircleIcon from "@/assets/icons/alert-circle.svg"
import { BoltaEvent } from "@/utils/mixpanels"
import { usePopover } from "@/hooks/usePopover"
import { Progress, Skeleton } from "@nextui-org/react"
import moment from "moment"
import { useBreakpointStore } from "@/store/layoutStore"
import { useEffect, useState } from "react"
import { useSnackbarStore } from "@/store/snackbarStore"
import Tooltip from "@/components/Tooltip/Tooltip"

export default function BottomMenu() {
  const authorizedFeatures = useProfileStore.getState().getAuthorizedFeatures()
  const isMobile = useBreakpointStore(state => state.isMobile)
  const openSnackbar = useSnackbarStore(state => state.openSnackbar)
  const profile = useProfileStore(state => state.profile)
  const isSubscriptionLoaded = useSubscriptionStore(
    state => state.isSubscriptionLoaded,
  )
  const subScriptionInfo = useSubscriptionStore(state => state.subscriptionInfo)
  const isIssuanceExeedCapcity = useSubscriptionStore(
    state => state.isIssuanceExeedCapcity,
  )(subScriptionInfo)
  const channelTalk = useChannelTalk()
  const leftPopover = usePopover("left-popover")
  const workspaceSwitchPopover = usePopover("workspace-switch-base-popover")
  const [text, setText] = useState("")

  useEffect(() => {
    if (!subScriptionInfo?.subscription) return
    setText(
      `이번 달 발행량이 모두 소진되었습니다.\n${moment(
        subScriptionInfo.subscription.expiresAt,
      )
        .add(1, "day")
        .format("YYYY.MM.DD")}에 발행량이 충전됩니다.`,
    )
  }, [subScriptionInfo])

  const openSwitchPopover = () => {
    const el = document.querySelector("#left-popover .MuiPopover-paper")

    workspaceSwitchPopover.setBaseAnchorElement(el as HTMLElement)
  }

  if (!profile || !profile.workspace) {
    return null
  }

  return (
    <>
      {(authorizedFeatures.includes("TAX_INVOICE_ISSUE_REQUEST") ||
        authorizedFeatures.includes("TAX_INVOICE_ISSUE_APPROVAL")) && (
        <Tooltip
          content={text}
          placement={"right"}
          offset={10}
          isDisabled={
            isMobile ||
            !subScriptionInfo?.subscription?.issuanceCapacity ||
            !isIssuanceExeedCapcity
          }
        >
          <div
            className={`bg-sub mx-6 mb-3 flex flex-col rounded-lg px-5 py-4 ${isIssuanceExeedCapcity ? "cursor-pointer" : ""}`}
            onClick={() => {
              if (
                isMobile &&
                subScriptionInfo?.subscription?.issuanceCapacity &&
                isIssuanceExeedCapcity
              ) {
                openSnackbar(text, "info")
              }
            }}
          >
            <div className="flex items-center justify-between text-secondary">
              <Skeleton isLoaded={isSubscriptionLoaded} className="rounded-lg">
                <div className="text-[12px]">
                  <div className="flex items-center gap-1">
                    <span>발행량</span>
                    {subScriptionInfo?.subscription?.issuanceCapacity &&
                      isIssuanceExeedCapcity && <AlertCircleIcon />}
                  </div>
                </div>
              </Skeleton>
              <Skeleton isLoaded={isSubscriptionLoaded} className="rounded-lg">
                <div className="text-[13px]">
                  {subScriptionInfo?.subscription?.issuanceCapacity ? (
                    <>
                      {subScriptionInfo.currentUsage.issuanceCount} /{" "}
                      <span className="text-quaternary">
                        {subScriptionInfo.subscription.issuanceCapacity}
                      </span>
                    </>
                  ) : (
                    <div className="flex items-center gap-1">
                      <InfinityIcon />
                      <span className="text-quaternary">(무제한)</span>
                    </div>
                  )}
                </div>
              </Skeleton>
            </div>
            {!isSubscriptionLoaded && <div className="mt-1"></div>}
            <Skeleton isLoaded={isSubscriptionLoaded} className="rounded-lg">
              {!isSubscriptionLoaded && <div className="h-[8px]"></div>}
              {subScriptionInfo?.subscription?.issuanceCapacity && (
                <Progress
                  radius="sm"
                  value={subScriptionInfo.currentUsage.issuanceCount}
                  maxValue={subScriptionInfo.subscription.issuanceCapacity}
                  className="mt-1 h-[8px]"
                  classNames={{
                    indicator: "!bg-[#FFC299]",
                  }}
                />
              )}
            </Skeleton>
          </div>
        </Tooltip>
      )}
      <div
        className="bg-sub mx-6 cursor-pointer rounded-xl px-5 py-4"
        onClick={e => leftPopover.setBaseAnchorElement(e.currentTarget)}
      >
        <div className="flex items-center justify-between">
          <div className="font-semibold">{profile.workspace.organizationName}</div>
          <SettingsIcon />
        </div>
        <div className="text-tertiary mt-1 flex flex-wrap items-center">
          <div className="mr-1 text-sm">{profile.account.displayName}</div>
          <div className="rounded-full border bg-white px-2 py-[3px] text-xs">
            {profile.collaborator.roleDisplayName}
          </div>
        </div>
      </div>

      {/* 하단 메뉴 팝오버 */}
      <Popover
        id={leftPopover.id}
        open={leftPopover.open}
        anchorEl={leftPopover.baseAnchorElement}
        className="bolta-popover"
        onClose={() => leftPopover.setBaseAnchorElement(null)}
        anchorOrigin={{
          vertical: -10,
          horizontal: -10,
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="min-w-[272px] rounded-lg border bg-white p-2">
          <div className="sub-title mt-1">워크스페이스</div>
          <MenuItem icon={<SwitchIcon />} onClick={() => openSwitchPopover()}>
            워크스페이스 전환
            <ArrowRightIcon className="ml-auto" />
          </MenuItem>

          <hr className="my-2" />
          <div className="sub-title">관리</div>
          <MenuItem
            icon={<Settings2Icon />}
            onClick={() => {
              BoltaEvent.track("clicked__settings__left_side_bar")
              window.location.href = "/settings/account"
            }}
          >
            설정
          </MenuItem>

          {(authorizedFeatures.includes("COLLABORATOR_INVITATION") ||
            authorizedFeatures.includes("COLLABORATOR_AND_ROLE_MANAGEMENT")) && (
            <MenuItem
              icon={<TeamIcon />}
              onClick={() => {
                BoltaEvent.track("clicked__members__left_side_bar")
                window.location.href = "/team/members"
              }}
            >
              {authorizedFeatures.includes("COLLABORATOR_AND_ROLE_MANAGEMENT")
                ? "팀원 및 권한"
                : "팀원 관리"}
            </MenuItem>
          )}

          {authorizedFeatures.includes("BILLING_MANAGEMENT") && (
            <MenuItem
              icon={<CardIcon />}
              onClick={() => {
                BoltaEvent.track("clicked__billing__left_side_bar")
                window.location.href = "/billing"
              }}
            >
              구독 및 결제
              {subScriptionInfo?.subscription?.isFreeTrial && (
                <div className="-my-[2px] ml-2 mr-3 inline-block rounded border-1 border-[#FF6600] px-2 py-[1px] !text-[12px] font-medium text-primary">
                  Free Trial
                </div>
              )}
            </MenuItem>
          )}

          <hr className="my-2" />

          <MenuItem
            icon={<HelpIcon />}
            onClick={() => {
              leftPopover.setBaseAnchorElement(null)
              BoltaEvent.track("clicked__inquiry__left_side_bar")
              channelTalk.openMessenger()
            }}
          >
            문의하기
          </MenuItem>
          <MenuItem
            icon={<BookIcon />}
            onClick={() => {
              leftPopover.setBaseAnchorElement(null)
              BoltaEvent.track("clicked__guide__left_side_bar")
              window.open("https://docs.bolta.io", "_blank")
            }}
          >
            사용 가이드
          </MenuItem>
          <MenuItem
            icon={<LogoutIcon />}
            onClick={() => {
              BoltaEvent.logout()
              logout("/login")
            }}
          >
            로그아웃
          </MenuItem>
        </div>
      </Popover>

      <WorkspaceSwitch
        anchorEl={workspaceSwitchPopover.baseAnchorElement}
        onClose={() => workspaceSwitchPopover.setBaseAnchorElement(null)}
      />
    </>
  )
}
